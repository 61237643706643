@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/fonts"
@use "../../../library/text"

.homeHeadlinesRow--displayMode-smallBox.homeHeadlinesRow--appearance-light


    .homeHeadlinesRow__collectionTitle
        background-color: #FFFFFF !important
        color: #000000
        border-top: 1px solid #A2A2A2
        border-left: 1px solid #A2A2A2
        border-right: 1px solid #A2A2A2

    .subHeader__link a:after
        border-color: #000000

    .homeHeadlines--displayMode-smallBox
        border-bottom: 1px solid #A2A2A2
        border-left: 1px solid #A2A2A2
        border-right: 1px solid #A2A2A2

    .mainHeadlines__list
        background-color: #FFFFFF

        .storyCard
            border-color: #DEDEDE
            &__suptitle
                color: #686868
            &__title
                color: #2F2F2F
            &__lead
                color: #232325

            &--position-1
                .storyCard
                    &__details
                        border-color: #DEDEDE
                &:hover
                    .storyCard__titleLink
                        color: black
