@use "include-media/dist/include-media"

.homePage

    &__callForTestimony
        margin-left: auto
        margin-right: auto
        width: calc(100% - 30px)

        @include include-media.media(">=min")
            width: 100%
