@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/fonts"
@use "../../../library/text"
@use "../../podcastVisual"

.homeHeadlinesRow--displayMode-smallBox

    margin-top: 0.3125rem
    display: grid

    .subHeader__link
        display: block
        padding-right: 1rem

        a:after
            border-color: #F2F2F2
            right: 5px
            @include include-media.media(">=full")
                right: 26px


    .storyCard__blurredOverlay
        display: none !important


    .mainHeadlines__list
        background-color: #2D2D2D

    .mainHeadlines__row:nth-child(1)
        .homeHeadlinesRow
            &__listItem
                margin-top: 0 !important

                &:last-child
                    padding-bottom: 0

    .mainHeadlines__row:nth-child(2),
    .mainHeadlines__row:nth-child(3)
        width: 100% !important
        padding: 0.9375rem 0.9375rem 0 0.9375rem

        .mainHeadlines__GroupList
            gap: 1rem !important

        @include include-media.media(">=min")
            padding: 0
            margin-top: 0.9375rem !important
            width: 709px !important
            .mainHeadlines__GroupList
                gap: 1rem !important

        @include include-media.media(">=full")
            width: 960px !important
            .mainHeadlines__GroupList
                gap: 1.875rem !important

        .storyCard
            margin: 0.9375rem 0 0 0
            border-color: #dedede

            &__cover
                display: block

    .homeHeadlinesRow
        &__main
            margin-top: 0.9375rem

        &__collectionTitle
            font-size: 1.25rem
            text-transform: none
            font-weight: 400
            color: #F2F2F2
            background-color: #2D2D2D !important
            position: relative !important
            left: 0 !important
            width: calc(100% - 1.875rem)
            margin: 0 0.9375rem

            @include include-media.media(">=min")
                line-height: 26px
                font-size: 1.5rem
                width: 709px
                margin: 0

            @include include-media.media(">=full")
                line-height: 66px
                font-size: 1.625rem
                width: 960px

        &__collectionTitleLink
            width: 100%

        &__collectionTitleText
            padding-right: 1rem
            @include include-media.media(">=full")
                padding-right: 2.5rem

    @include include-media.media(">=min")
        width: 1024px

        .mainHeadlines__list
            width: 709px
            grid-template-columns: 468px 220px

        .homeHeadlinesRow
            &__listItem,
            &__listItem:nth-child(2),
            &__listItem:nth-child(3),
            &__listItem:nth-child(4)
                width: 217px

    @include include-media.media(">=full")

        width: 1290px

        .mainHeadlines__list
            width: 960px
            grid-template-columns: 630px 308px

        .homeHeadlinesRow
            &__listItem,
            &__listItem:nth-child(2),
            &__listItem:nth-child(3),
            &__listItem:nth-child(4)
                width: 300px

    .homeHeadlines--displayMode-smallBox
        .storyCard
            &:not(&--position-1)
                @include podcastVisual.podcastVisual--storycard-small

            border-color: #404040
            padding-bottom: 15px
            margin: 0 15px auto 15px

            @include include-media.media(">=min")
                margin-left: 0
                padding-bottom: 15px

            .storyCard__inner
                display: flex

            .storyCard__cover
                order: 2
                display: block
                width: 30%
                padding-top: 0

            .storyCard__image
                aspect-ratio: 1

            .storyCard__details
                width: 70%
                padding-right: 1rem

            .storyCard__suptitle,
            .storyCard__title,
            .storyCard__lead
                color: #F2F2F2

            .storyCard__suptitle
                font-size: 0.875rem
                @include include-media.media(">=min")
                    font-size: 0.9375rem
                @include include-media.media(">=full")
                    font-size: 1.0rem

            .storyCard__title
                font-size: 1.125rem
                @include include-media.media(">=min")
                    font-size: 1.0625rem

                @include include-media.media(">=full")
                    font-size: 1.375rem

            &--position-1
                padding-top: 15px
                border-top: 1px solid #dedede
                padding-bottom: 0

                @include podcastVisual.podcastVisual--storycard-large

                @include include-media.media(">=min")
                    margin: auto 0 !important
                    border-top: none
                    padding-top: 0

                & .storyCard__inner
                    display: initial

                .storyCard__details
                    margin: 1rem 0
                    width: initial
                    padding: 0
                    top: 0

                    @include include-media.media(">=min")
                        border-right: 1px solid #404040
                        padding: 0 1rem
                        width: 468px

                    @include include-media.media(">=full")
                        width: 630px

                .storyCard__cover
                    height: auto
                    padding-top: (2 / 3) * 100%
                    order: initial
                    aspect-ratio: initial
                    width: initial

                    &:after
                        display: none

                    @include include-media.media(">=min")
                        width: 468px

                    @include include-media.media(">=full")
                        width: 630px

                .storyCard__image
                    height: 100%
                    width: 100%

                .storyCard__suptitle
                    font-size: 1.25rem
                    @include include-media.media(">=min")
                        font-size: 1.125rem
                    @include include-media.media(">=full")
                        font-size: 1.25rem

                .storyCard__title
                    font-size: 1.875rem
                    @include include-media.media(">=min")
                        font-size: 2.0rem
                    @include include-media.media(">=full")
                        font-size: 2.25rem

                .storyCard__lead
                    @include include-media.media("<min")
                        display: none !important
                    @include include-media.media(">=min")
                        font-size: 1.0rem
                    @include include-media.media(">=full")
                        font-size: 1.375rem

                .storyCard__leadLink
                    @include include-media.media(">=min")
                        @include text.line-clamp(3)
