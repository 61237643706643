@use "include-media/dist/include-media"
@use "../../../library/breakpoints"

.banner__link
    text-decoration: none
    line-height: 0
    display: inline-block
    padding-top: 54%
    width: 100%
    height: 100%

    @include include-media.media(">=min")
        padding-top: 0
        width: 1024px
        height: 125px

    @include include-media.media(">=full")
        display: inline-block
        width: 1290px
        height: 122px
