@use "include-media/dist/include-media"
@use "../../library/breakpoints"

.homeRow--newsletterSubscriptionBox
    width: 300px
    margin-left: auto
    margin-right: auto

.homeRow
    @include include-media.media(">=min")
        width: breakpoints.$page-min-width
        margin-right: auto
        margin-left: auto
        display: block
        clear: both

    @include include-media.media(">=full")
        width: breakpoints.$page-full-width

    &__body
        max-width: calc(100% - 30px)
        margin: 0 auto 32px
        @include include-media.media(">=min")
            margin: 0 0 32px
            max-width: none

    .podcastEpisode__visual
        position: absolute !important