@use "include-media/dist/include-media"
@use "../../../../library/breakpoints"

.homeRow__column--col-3

    @include include-media.media(">=min")
        width: 333px
        flex-basis: 333px

    @include include-media.media(">=full")
        width: 410px
        flex-basis: 410px
