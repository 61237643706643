@use "include-media/dist/include-media"
@use "../../../../static/sass/library/breakpoints"
@use "../../../../static/sass/library/fonts"

.digitalStoryBanner
  display: flex
  font-family: fonts.$fontVerlag
  text-align: center
  color: #FFFFFF
  height: 250px

  @media screen and (min-width: 410px)
    justify-content: center
    height: 68vw

  @include include-media.media(">=min")
    height: 125px

  &__content

    @include include-media.media(">=min")
      display: flex
      text-align: left

  &__header
    @include include-media.media(">=min")
      margin: 10px 0 0 25px
    @include include-media.media(">=full")
      margin: 10px 0 0 0

  &__suptitle
    font-size: 13px
    line-height: 17px
    text-transform: uppercase
    padding: 10px 0 4px 0

    @media screen and (min-width: 300px) and (max-width: 371px)
      font-size: 10px
      line-height: 12px

    @media screen and (min-width: 410px)
      font-size: 2vw
      line-height: 3.2vw
      padding: 5vw 0 1vw 0

    @include include-media.media(">=min")
      font-size: 14px
      line-height: 17px
      padding: 10px 0 5px 0

  &__title
    color: #b3a8be
    font-size: 30px
    line-height: 32px
    font-weight: 800
    text-transform: uppercase
    padding: 0 60px

    @media screen and (min-width: 300px) and (max-width: 371px)
      font-size: 22px
      line-height: 24px

    @media screen and (min-width: 410px)
      font-size: 6.17vw
      line-height: 6.5vw

    @include include-media.media(">=min")
      font-size: 32px
      line-height: 34px
      padding: 0
      max-width: 150px
      width: auto

  &__description
    font-size: 15px
    line-height: 19px
    padding: 10px 30px 0 30px

    @media screen and (min-width: 300px) and (max-width: 371px)
      font-size: 12px
      line-height: 15px
      padding: 7px 15px 0 15px

    @media screen and (min-width: 410px)
      font-size: 2.65vw
      line-height: 3.2vw
      max-width: 56vw
      width: auto
      padding-top: 4vw
      margin: auto

    @include include-media.media(">=min")
      margin: 0
      font-size: 17px
      line-height: 21px
      font-weight: 300
      max-width: 750px
      width: auto
      padding: 30px 30px 0 70px

    @include include-media.media(">=full")
      padding: 30px 30px 0 270px

  &__linkWrapper
    display: flex
    justify-content: center

    @include include-media.media(">=min")
      align-self: center
      justify-content: flex-end
      margin-right: 40px

    @include include-media.media(">=full")
      padding-left: 40px

  &__link
    font-family: "rubrik", Arial, "Helvetica Neue", Helvetica, sans-serif
    font-size: 13px
    line-height: 13px
    margin-top: 15px
    display: flex
    justify-content: center
    width: 250px
    padding: 8px
    text-decoration: none
    backdrop-filter: blur(8px)
    color: #FFFFFF
    border: 1px solid #FFFFFF

    @media screen and (min-width: 410px)
      font-size: 2.8vw
      line-height: 4vw
      width: 55vw
      padding: 1.5vw
      margin-top: 4vw
      border-radius: 50px

    @include include-media.media(">=min")
      margin: 0
      font-size: 16px
      line-height: 21px
      padding: 14px 10px
      width: 270px

.digitalStoryBanner__link:hover
  border: solid 1px #cbcbcb
  color: #cbcbcb
  background-color: #000000

.digitalStoryBanner__link:active
  border: solid 1px #989898
  color: #989898
  background-color: #000000