@use "include-media/dist/include-media"
@use "../../library/breakpoints"

@use "./--qty/filler--qty-1"
@use "./--qty/filler--qty-2"
@use "./--qty/filler--qty-5"
@use "./--qty/filler--qty-7"

@use "__text/filler__text"

@use "__btn/filler__btn"
@use "__btn/--qty/filler__btn--qty-1"
@use "__btn/--qty/filler__btn--qty-2"
@use "__btn/--qty/filler__btn--qty-5"
@use "__btn/--qty/filler__btn--qty-7"

@use "__textEm/filler__textEm"

.filler
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%
    height: 43%
    margin-bottom: auto
    background-color: #e3e3e3
    padding: 40px

    @include include-media.media(">=min")
        padding: 60% 0 20px 30px

    &__container

        @include include-media.media(">=min")
            height: auto
            width: 100%
            margin-top: -63%

    &__whatIsAnXTR
        font-size: 0.75rem
        font-weight: 400
        color: #686868
        position: relative
        padding: 1em 0 // padding rather than margin to have more space to click, esp. on mobile
        width: 100vw
        margin-left: -40px

    &__whatIsAnXTRPopup
        text-align: left

.filler--discoverXTR
    text-align: center
    @include include-media.media(">=min")
        text-align: left




