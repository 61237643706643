@use "include-media/dist/include-media"
@use "../../videoSticker"
@use "../../podcastSticker"
@use "../../podcastVisual"
@use "../../../library/breakpoints"
@use "../../../library/text"
@use "../../../../../templates/twig/_components/storyCard/__cover/storyCard__cover--square"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"

.homeRow--sectionDuet
    .storyCard

        &__suptitle
            margin-bottom: 10px

        &__title
            margin-bottom: 12px

        &--position-1,
        &--position-2

            .storyCard__suptitle

                font-size: 1.125rem
                font-weight: 300

                @include include-media.media(">=min")
                    font-size: 0.9375rem
                    line-height: 1.2em

                &--featuredAuthor
                    font-size: 1.0625rem
                    @include include-media.media(">=min")
                        font-size: 0.9375rem

                @include include-media.media(">=full")
                    font-size: 1.125rem
                    line-height: 1.2em
                    &--featuredAuthor
                        font-size: 1.0625rem

            .storyCard__suptitleLink
                @include include-media.media(">=min")
                    @include text.line-clamp(1)
                    line-height: 1.2em

            .storyCard__title

                font-size: 1.875rem
                font-weight: 900

                @include include-media.media(">=min")
                    font-size: 1.25rem
                    font-weight: 600
                    line-height: 1.2em

                &--featuredAuthor
                    font-size: 1.75rem

                    @include include-media.media(">=min")
                        font-size: 1.1875rem

                @include include-media.media(">=full")
                    font-size: 1.5rem
                    line-height: 1.275em
                    &--featuredAuthor
                        font-size: 1.4375rem

        @include include-media.media("<min")
            &--position-2
                .storyCard

                    &__details
                        display: inline-block
                        vertical-align: top
                        width: calc(100% - 100px - 20px)
                        @include include-media.media(">=min")
                            width: calc(100% - 67px - 16px)
                        @include include-media.media(">=full")
                            width: calc(100% - 80px - 20px)

                    &__suptitle
                        font-size: 0.875rem
                        font-weight: 300

                        &--featuredAuthor
                            font-size: 0.875rem

                        @include include-media.media(">=min")
                            font-size: 0.9375rem
                            font-weight: 300

                            &--featuredAuthor
                                font-size: 0.9375rem

                        @include include-media.media(">=full")
                            font-size: 1rem

                            &--featuredAuthor
                                font-size: 0.9375rem

                    &__title

                        font-size: 1.125rem
                        font-weight: 900
                        line-height: 1.22em

                        &--featuredAuthor
                            font-size: 1.0625rem

                        @include include-media.media(">=min")
                            font-size: 1.125rem
                            font-weight: 600

                            &--featuredAuthor
                                font-size: 1.0625rem

                        @include include-media.media(">=full")
                            font-size: 1.25rem

                            &--featuredAuthor
                                font-size: 1.1875rem

                    &__cover
                        @include storyCard__cover--square.style

                    &__image
                        @include storyCard__image--square.style

                @include podcastVisual.podcastVisual--storycard-small

                @include include-media.media(">=min", "<full")
                    @include videoSticker.video-sticker-small
                    @include podcastSticker.podcast-sticker-small
