@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/variables"

.banner--type-digitalStoryBanner
  background-image: url(variables.$img-path + "/digital-story-banner/digital-story-banner-mobile@2x.png")

  @include include-media.media(">=min")
    background-image: url(variables.$img-path + "/digital-story-banner/digital-story-banner-1024@2x.png")

  @include include-media.media(">=full")
    background-image: url(variables.$img-path + "/digital-story-banner/digital-story-banner-1290@2x.png")