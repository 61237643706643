@use "include-media/dist/include-media"
@use "../../podcastVisual"
@use "../../../library/breakpoints"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"
.homeRow--storyTriplet
    .homeSectionHeadlines
        @include include-media.media("<min")
            @include podcastVisual.podcastVisual--storycard-small

    .storyCard

        &__details
            display: inline-block
            vertical-align: top
            width: calc(100% - 100px - 20px)
            @include include-media.media(">=min")
                width: 162px
            @include include-media.media(">=full")
                width: 205px

        &__suptitle
            font-size: 0.875rem
            font-weight: 300
            margin-bottom: 10px

            &--featuredAuthor
                font-size: 0.875rem

            @include include-media.media(">=min")
                font-size: 0.9375rem

                &--featuredAuthor
                    font-size: 0.9375rem

            @include include-media.media(">=full")
                font-size: 1rem

                &--featuredAuthor
                    font-size: 0.9375rem

        &__title
            font-size: 1.125rem
            font-weight: 900
            line-height: 1.22em
            margin-bottom: 12px

            &--featuredAuthor
                font-size: 1.0625rem

            @include include-media.media(">=min")
                font-size: 1.125rem
                font-weight: 600

                &--featuredAuthor
                    font-size: 1.125rem

            @include include-media.media(">=full")
                font-size: 1.25rem

                &--featuredAuthor
                    font-size: 1.1875rem
        &__cover
            display: inline-block
            width: 100px !important
            height: 100px !important
            overflow: hidden
            padding-top: 0
            margin-right: 11px
            @include include-media.media(">=min")
                width: 155px !important
                height: 100px !important
            @include include-media.media(">=full")
                width: 190px !important
                height: 127px !important

        &__image
            @include include-media.media("<min")
                @include storyCard__image--square.style

        @include include-media.media("<min")
            @include podcastVisual.podcastVisual--storycard-small
