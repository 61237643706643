@use "include-media/dist/include-media"
@use "../../../library/breakpoints"

.homeHeadlinesRow__main
    @include include-media.media(">=min")
        grid-row: 1
        grid-column: 1
        padding-right: 15px

    @include include-media.media(">=full")
        padding-right: 30px

