@use "include-media/dist/include-media"
@use "../../../../library/breakpoints"

.homeRow__column--col-4

    @include include-media.media(">=min")
        flex-basis: 219px
        width: 219px
        &:last-child
            flex-basis: 300px
            width: 300px

    @include include-media.media(">=full")
        flex-basis: 300px
        width: 300px
