@use "include-media/dist/include-media"
@use "library/variables"
@use "library/reset"
@use "library/colors"
@use "library/text"
@use "library/fonts"

@use "components/spinner/spinner"

@use "library/breakpoints"

@use "common/autoPromoBox"

@use "common/section/contestList"

@use "components/adsWrapper"
@use "components/lpplusBanner"
@use "components/greyLineToppedBox"
@use "components/header"
@use "components/subHeader"
@use "components/pageBlock"
@use "components/pageBox/pageBox"
@use "components/flashNews"
@use "components/button/button"
@use "components/callForTestimony/callForTestimony"
@use "components/homePage/homePage"
@use "components/podcastVisual"
@use "components/reponsiveList/responsiveList"
@use "components/filler/filler"
@use "components/homeRow/homeRowBase"
@use "components/homeHeadLinesRow/homeHeadlinesRowBase"
@use "components/banner/banner"
@use "../../templates/twig/_components/storyCard/storyCard"
@use "../../templates/twig/_components/digitalStoryBanner/digital-story-banner"

body
    background: #f9f9f8

hr
    margin: 0
    height: 4px
    border: 0
    background-color: #232325

.storyCard
    margin-top: 20px // todo component should not know it's outside. It should be on a bloc__element

.homeSectionHeadlines ul
    max-width: calc(100% - 30px)
    margin: 0 auto

.homeHeadlinesCard
    &__publicationUpdateDate
        &::before
            content: "|"
            margin: 0 5px 0 2px
    &__publicationUpdateDate:first-child
        &::before
            content: ""
            margin: 0

.homeSectionHeadlines
    &--layout-column
        ul
            display: flex
            flex-direction: column
            justify-content: space-between

    &--layout-row
        ul
            display: flex
            flex-direction: column
            @include include-media.media(">=min")
                flex-wrap: wrap


        .list__item

            order: 2

            &--group
                flex-basis: 100%
                .thematicGroup

                    &__list
                        flex-basis: 100%

                    &__listItem
                        @include include-media.media(">=min")
                            flex-basis: 324px !important
                            width: 324px !important
                        @include include-media.media(">=full")
                            flex-basis: 399px !important
                            width: 399px !important

                margin-top: 2rem
                &:first-child
                    margin-top: 1rem

                @include include-media.media(">=min")
                    &:nth-child(2),
                    &:nth-child(3)
                        order: 3


.homeSectionHeadlines
    .whatIsAnXtra
        position: absolute
        top: -10px
        left: 15px
        right: 15px
        font-family: fonts.$fontRubrik
        font-size: 0.875rem
        font-weight: 300
        line-height: 1.43
        display: none
        color: #fff
        opacity: 0.9
        background-color: #181818
        padding: 15px
        z-index: 1

        &__close
            background-image: url("assets/ico_close_b.inline.svg")
            position: absolute
            right: 10px
            top: 10px
            width: 16px
            height: 16px
            background-size: 16px
            background-repeat: no-repeat
            background-position: 50%
            cursor: pointer
            padding: 15px

            &:hover
               background-color: #414141

        &__title
            display: block
            color: #dbdbdb
            padding-bottom: 15px

        &__displayed
            display: block


.homeTrending
    margin-right: auto
    margin-left: auto
    @include include-media.media("<min")
        @include podcastVisual.podcastVisual--storycard-small
    li
        &:nth-of-type(n+4)
            display: none

    &__tabList
        position: relative
        padding: 0 15px
        @include include-media.media(">=min")
            padding: 0

    &__tab
        display: none
        color: #000
        text-transform: uppercase
        cursor: default
        @include include-media.media(">=min")
            cursor: pointer

        font-size: 1em
        font-family: fonts.$fontRubrik
        position: absolute

        line-height: 1em
        height: 46px
        top: -42px
        left: 15px

        &--pos2
            display: block
            position: relative
            padding: 0
            margin: 12px 0 28px 0
            top: initial
            left: initial
            height: auto

    &__tabContent
        width: 100%
        counter-reset: trendingCard
        display: none
        &--pos2
            display: block

.homeContactUs
    margin-right: 15px
    margin-left: 15px

@include include-media.media(">=min")
    hr
        clear: both
        width: breakpoints.$page-min-width
        margin: 30px auto 0 auto

    .homeHeadlines,
    .homeSectionHeadlines ul
        max-width: initial
        margin: initial

    .homeTrending
        width: breakpoints.$page-min-width
        overflow-x: hidden

        li
            &:nth-of-type(n+4)
                display: initial

        &__tabList
            position: relative

        &__tabList
            &::after
                content: ""
                position: absolute
                top: 46px
                width: 100%
                border-bottom: 1px solid #000

        &__tab
            display: initial
            min-width: 200px
            font-size: 0.9em
            font-weight: 500
            line-height: 46px
            position: absolute
            left: initial
            top: 0

            background-color: #d4d4d4
            color: #000
            width: 200px
            height: 46px
            text-align: center

            &--pos1
                right: calc(205px * 2)

            &--pos2
                top: 0
                right: calc(205px * 1)
                position: absolute
                left: initial
                margin: 0
                height: initial

            &--pos3
                right: 0

            &--active
                color: #fff
                background-color: #000

        &__tabContent,
        &__tabContent--pos2
            display: none
            width: 100%

        &__tab--active + &__tabContent
            display: block
            padding-top: 42px

        &__articleList
            display: flex
            flex-direction: row
            justify-content: space-between

            li
                flex-basis: 160px

    .homeSectionHeadlines

        .whatIsAnXtra
            top: 5px
            left: 115px
            font-family: rubrik, Arial, sans-serif
            font-size: 0.75rem
            font-weight: 300
            line-height: 1.43
            width: 291px
            padding: 15px
            @include include-media.media(">=full")
                font-size: 0.875rem

        &--layout-column
            ul
                display: flex
                flex-direction: row
                justify-content: space-between

            li
                flex-basis: 246px
                width: 246px

        &--layout-row
            ul
                flex-direction: row
                justify-content: space-between

        &--itemPerRow-4
            ul li
                flex-basis: 246px
                width: 246px

        &--itemPerRow-3
            ul li
                flex-basis: 335px
                width: 335px

    .fullWidthAd
        width: 100%
        height: 415px
        margin-bottom: 70px

        &__banner
            height: 415px
            background-attachment: fixed
            background-size: cover
            background-position: 50% 50%

    .homeContactUs
        max-width: 246px
        margin-left: 0
        margin-right: 0
        margin-bottom: 15px

@include include-media.media(">=full")
    hr
        width: breakpoints.$page-full-width
        margin: 35px auto 0 auto

    .homeHeadlines,
    .homeSectionHeadlines ul
        max-width: initial
        margin: initial

    .homeSectionHeadlines
        .whatIsAnXtra
            top: 15px
            left: 115px
            font-family: rubrik, Arial, sans-serif
            font-size: 0.875rem
            font-weight: 300
            line-height: 1.43
            width: 291px
            padding: 15px

            &__title
                padding-bottom: 15px

    .homeTrending
        width: 1290px

        &__tabList
            &::after
                top: 46px

        &__tab
            min-width: 250px
            line-height: 46px
            top: 0
            width: auto
            padding: 0 10px
            height: 46px

            &--pos1
                right: calc(254px * 2)

            &--pos2
                right: calc(254px * 1)

            &--pos3
                right: 0

        &__tabContent,
        &__tabContent--pos2
            width: 100%

        &__articleList
            li
                flex-basis: 190px

    .homeSectionHeadlines

        &--layout-column
            li
                flex-basis: 300px
                width: 300px

        &--itemPerRow-4
            ul li
                flex-basis: 300px
                width: 300px

        &--itemPerRow-3
            ul li
                flex-basis: 412px
                width: 412px

    .fullWidthAd
        height: 415px
        margin-bottom: 70px

        &__banner
            height: 415px

    .homeContactUs
        max-width: 300px
