@use "include-media/dist/include-media"
@use "../../../../library/breakpoints"

.homeRow__column--col-2

    @include include-media.media(">=min")
        flex-basis: 504px
        width: 504px

    @include include-media.media(">=full")
        flex-basis: 630px
        width: 630px
