@use 'include-media/dist/include-media'
@use '../../library/breakpoints'
@use '--type-digitalStoryBanner/banner--type-digitalStoryBanner'
@use '__link/banner__link'

.banner
    margin: 60px auto 0 auto
    background-repeat: no-repeat
    width: calc(100% - 30px)
    background-size: contain
    line-height: 0
    text-align: center
    max-height: 350px
    @include include-media.media(">=min")
        background-size: 1024px 125px
        width: 1024px

    @include include-media.media(">=full")
        background-size: 1290px 125px
        width: 1290px

    &__image
        max-width: 100%
        max-height: 350px
