@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/fonts"

.filler__text
    font-family: fonts.$fontRobotoSerif
    font-size: 32px
    font-weight: 300
    font-stretch: normal
    font-style: normal
    line-height: 1.17
    letter-spacing: normal
    color: #353535

    &-discoverXTR
        font-size: 22px
        font-weight: 400

    &--qty-1,
    &--qty-2,
    &--qty-5
        @include include-media.media(">=min")
            font-size: 1.375rem
            font-weight: 300
        @include include-media.media(">=full")
            font-size: 1.875rem
            font-weight: 300

    &--qty-7
        @include include-media.media(">=min")
            font-size: 1.125rem
            font-weight: 300
        @include include-media.media(">=full")
            font-size: 1.375rem
            font-weight: 300

