@use "include-media/dist/include-media"
@use "../../../library/breakpoints"

@use "./--xtra/responsiveList__item--xtra"

.responsiveList__item

    @include include-media.media(">=min")
        display: flex !important
        justify-content: center !important
        flex-grow: 0 !important
        flex-shrink: 0 !important
        flex-basis: 31.7% !important
        width: auto !important

    @include include-media.media(">=full")
        flex-basis: 32.04% !important

    &:nth-last-child(4):first-child,
    &:nth-last-child(4):first-child ~ *,
    &:nth-last-child(8):first-child,
    &:nth-last-child(8):first-child ~ *

        @include include-media.media(">=min")
            flex-basis: 23.16% !important
            .storyCard__suptitleLink
                font-size: 0.8125rem !important
                font-weight: 300 !important
            .storyCard__titleLink
                font-size: 0.9375rem !important
                font-weight: 500 !important
                line-height: 1.69

        @include include-media.media(">=full")
            flex-basis: 23.54% !important
            .storyCard__suptitleLink
                font-size: 0.875rem !important
                font-weight: 300 !important
            .storyCard__titleLink
                font-size: 1rem !important
                font-weight: 500 !important
                line-height: 1.69
