@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/colors"
@use "../../../library/fonts"

.homeRow--doNotMissPosMidPage

    .homeRow
        &__list
            display: flex
            flex-direction: row
            flex-wrap: wrap
            justify-content: space-between

        &__item
            width: calc(50% - 7.5px)
            @include include-media.media(">=min")
                flex: 0 1 160px
                &--firstFourth
                    display: none
            @include include-media.media(">=full")
                flex: 0 1 190px

    .subHeader
        color: #232325
        border-top-color: #232325
        font-family: fonts.$fontRubrik

    .storyCard

        &__suptitle

            font-size: 1rem
            font-weight: 300
            line-height: normal !important
            color: colors.$STORY_CARD_SUPTITLE
            margin-bottom: 10px

            &--featuredAuthor
                font-size: 0.9375rem

            @include include-media.media(">=min")
                font-size: 0.9375rem

                &--featuredAuthor
                    font-size: 0.9375rem

            @include include-media.media(">=full")
                font-size: 1.0rem

                &--featuredAuthor
                    font-size: 0.9375rem

        &__title
            font-size: 1.125rem
            font-weight: 600
            line-height: normal !important
            color: colors.$STORY_CARD_TITLE_DARK_GREY
            margin-bottom: 12px

            &--featuredAuthor
                font-size: 1.0625rem

            @include include-media.media(">=min")
                font-size: 1.125rem

                &--featuredAuthor
                    font-size: 1.0625rem

            @include include-media.media(">=full")
                font-size: 1.25rem

                &--featuredAuthor
                    font-size: 1.125rem
