@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/fonts"

.homeRow--XTRA

    .storyCard

        border-color: #ccc
        width: 100%

        &__details
            padding: 8px 6px 8px 0
            line-height: 1.33

        &__suptitle
            margin-bottom: 10px

        &__suptitleLink
            font-size: 0.875rem !important
            font-weight: 400

            @include include-media.media(">=min")
                font-size: 0.875rem !important
                font-weight: 300 !important
            @include include-media.media(">=full")
                font-size: 0.875rem !important
                font-weight: 300 !important

        &__title
            margin-bottom: 12px

        &__titleLink
            font-size: 1.1875rem !important
            font-weight: 600
            margin-bottom: 0
            padding-bottom: 0

            @include include-media.media(">=min")
                font-size: 1.125rem !important
                font-weight: 500

            @include include-media.media(">=full")
                font-size: 1.1875rem !important
                font-weight: 500
