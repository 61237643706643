
@use "include-media/dist/include-media"
@use "../../../library/breakpoints"

.homeRow--empty

    .subHeader--section-XTR
        display: none

        @include include-media.media(">=min")
            display: block
            margin-bottom: 0

    .filler

        @include include-media.media(">=min")
            display: none
