@use "../../library/colors"
@use "../../library/fonts"

.contestBox
    &__header
        margin: 0
        background-color: colors.$DEF6
        border-top: 4px solid colors.$XTR
        padding: 1.4rem 0.4rem 1.4rem 0.8rem
        font-size: 1.7rem
        line-height: 1.7rem
        a
            display: block
            position: relative
            font-weight: 800
            color: inherit
            line-height: inherit
            font-size: inherit
            text-transform: uppercase
            text-decoration: none
            &:after
                display: block
                position: absolute
                font-family: fonts.$fontVerlag
                font-size: 3rem
                font-weight: 300
                right: 0.75rem
                top: 0
                content: ">"
        &:hover
            background-color: colors.$DEF8
        &--cinema
            color: colors.$CIN
            border-top-color: colors.$CIN
            a
                font-family: fonts.$fontRobotoSerif
                font-size: 1.625rem
                font-weight: 700


.contestItem

    &__link
        display: block
        border-top: 1px solid #dedede
        margin-top: 20px
        padding-top: 15px
        text-decoration: none
        &:hover
            .contestItem__visual
                opacity: 90%
    &__visual
        width: 100%
        aspect-ratio: 3/2
        object-fit: cover
    &__description
        color: #686868
        font-family: fonts.$fontVerlag
        font-size: 1.13rem
        line-height: 1.5rem
        letter-spacing: 0.02rem
        font-weight: 300
        margin-top: 10px
        strong
            font-weight: bold
        em
            font-style: italic
