@use "include-media/dist/include-media"
@use "../../../library/breakpoints"
@use "../../../library/fonts"
@use "../../../library/text"

.homeHeadlinesRow__collectionTitle
    position: absolute !important
    width: 100%
    @include include-media.media(">=min")
        height: 50px
    font-family: fonts.$fontVerlag
    font-weight: 400
    font-size: 1em
    color: white
    background-color: rgba(30, 30, 30, 0.7)
    z-index: 10
    text-transform: uppercase
    padding: 1.3rem 1rem
    .homeHeadlinesRow__collectionTitleText
        @include text.line-clamp(2)
        @include include-media.media(">=min")
            @include text.line-clamp(1)
        em
            font-style: italic
        sup
            line-height: 1
        sub
            vertical-align: sub
            font-size: smaller
            line-height: 1