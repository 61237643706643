
@use "include-media/dist/include-media"
@use "../../../../../static/sass/library/breakpoints"

@mixin style
    display: inline-block
    overflow: hidden
    height: 100px !important
    width: 100px !important
    padding-top: 0
    margin-right: 11px
    @include include-media.media(">=min")
        height: 67px !important
        width: 67px !important
    @include include-media.media(">=full")
        height: 80px !important
        width: 80px !important
    .storyCard__image
        aspect-ratio: 1
        object-fit: cover
