@use "include-media/dist/include-media"
@use "../../library/breakpoints"
@use "./__item/responsiveList__item"
@use "./__itemGroup/responsiveList__itemGroup"

.responsiveList

    margin: 0 15px 1rem 15px !important

    @include include-media.media(">=min")
        margin: 0 0 1rem 0 !important
        display: flex !important
        flex-wrap: wrap !important
        column-gap: 25px !important
        justify-content: normal !important
        max-width: none !important

    &__filler
        @include include-media.media("<min")
            display: none
