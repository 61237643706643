@use "../../../library/fonts"


.callForTestimony
    &__content
        color: #d4d4d4
        font-family: fonts.$fontRubrik
        font-size: 0.94em
        font-weight: 300
        line-height: 1.4em
        margin-bottom: 22px
        text-align: center

        // todo: can we put this generic styles in the reset file ?
        strong
            font-weight: bold
        em
            font-style: italic
        sub
            font-size: smaller
            vertical-align: sub
            line-height: 0
        sup
            font-size: smaller
            vertical-align: super
            line-height: 0
