@use "../../../library/fonts"


.callForTestimony
    &__title
        position: relative
        text-align: center
        text-transform: uppercase

        font-family: fonts.$fontRubrik
        font-weight: 400
        font-size: 1em
        background: #000
        color: #fff

        width: 130px
        margin: auto auto 13px
