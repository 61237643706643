@use "../library/breakpoints"
@use "include-media/dist/include-media"
@use "../library/fonts"

.header
    font-family: fonts.$fontRubrik
    font-weight: 700
    text-transform: uppercase
    font-size: 24px
    color: #232325
    padding: 28px 0 28px 15px
    margin: 0 auto -20px auto

    @include include-media.media(">=min")
        font-size: 20px
        padding: 30px 0
        max-width: breakpoints.$page-min-width

    @include include-media.media(">=full")
        font-size: 28px
        padding: 36px 0
        max-width: breakpoints.$page-full-width

    &--displayMode-grouped
        margin-bottom: 0

.homeHeadlinesRow__main
    .header
        @include include-media.media(">=min")
            display: none