@use "../../../library/fonts"

.filler__btn
  width: 72%
  margin-top: 7px
  height: 42px
  border-radius: 24px
  border: solid 1px black
  background-color: #e8e8e8
  cursor: pointer
  font-family: fonts.$fontRubrik
  font-size: 16px
  font-weight: 500
  font-stretch: normal
  font-style: normal
  line-height: normal
  letter-spacing: normal
  text-align: center
  color: black
  &:hover
    color: #343434
    border: solid 1px #343434
  &:active
    color: #656565
    border: solid 1px #656565

  &-discoverXTR
    margin-top: 1em
    width: 100%
    max-width: 214px
