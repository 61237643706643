@use "../../../library/colors"
@use "../../../library/text"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"
@use "include-media/dist/include-media"

.homeHeadlinesRow--displayMode-default

    margin-top: 0.3125rem

    @include include-media.media("<min")
        .homeHeadlinesRow__list
            max-width: calc(100% - 30px)
            margin: 0 auto

    .homeHeadlinesRow__main

        // todo remove legacy when old thematic group are removed.
        &--legacy
            .storyCard
                @include include-media.media("<min")
                    margin-top: 20px

        .storyCard

            &__inner
                overflow: hidden

            &__suptitle
                font-size: 1.125rem
                line-height: 1.28em
                font-weight: 300
                color: #686868

                &--featuredAuthor
                    font-size: 1.0rem

            &__title
                font-size: 1.875rem
                line-height: normal
                font-weight: 900
                color: colors.$STORY_CARD_TITLE

                &--featuredAuthor
                    font-size: 1.75rem

                @include include-media.media(">=min")
                    color: colors.$STORY_CARD_TITLE_DARK_GREY

            &__lead
                font-weight: 300

            &--position-1 // todo position-x is not longer working with thematicGroup

                .storyCard__publishedDate
                    color: colors.$PUBLISHED_DATE_LIGHT

                .storyCard__modifiedDate
                    color: colors.$MODIFIED_DATE_LIGHT

                .storyCard__suptitle
                    color: #D4D4D4

                    @include include-media.media(">=min")
                        font-size: 0.9375rem
                        line-height: 1.44em
                        @include text.line-clamp(1)

                        &--featuredAuthor
                            font-size: 0.9375rem

                    @include include-media.media(">=full")
                        font-size: 1.125rem
                        line-height: 1.44em

                        &--featuredAuthor
                            font-size: 1.0625rem

                .storyCard__title
                    color: white

                    @include include-media.media(">=min")
                        font-size: 1.4375rem

                        &--featuredAuthor
                            font-size: 1.375rem

                    @include include-media.media(">=full")
                        font-size: 2.25rem

                        &--featuredAuthor
                            font-size: 2.125rem

                .storyCard__lead

                    display: none

                    @include include-media.media(">=min")

                        display: initial
                        font-size: 0.875rem
                        line-height: 1.36em
                        color: colors.$STORY_CARD_SUPTITLE_LIGHT

                        &--featuredAuthor
                            font-size: 0.875rem

                    @include include-media.media(">=full")
                        font-size: 1.125rem
                        line-height: 1.33em

                        &--featuredAuthor
                            font-size: 1.0625rem

                .storyCard__image
                    @include include-media.media("<min")
                        @include storyCard__image--square.style


    .mainHeadlines__row:nth-child(2)
        .storyCard__suptitle

            @include include-media.media(">=min")
                font-size: 0.9375rem

                &--featuredAuthor
                    font-size: 0.875rem

            @include include-media.media(">=full")
                font-size: 1rem

                &--featuredAuthor
                    font-size: 0.9375rem

        .storyCard__suptitleLink
            @include include-media.media(">=min")
                @include text.line-clamp(1)
                line-height: 1.2em

        .storyCard__title

            @include include-media.media(">=min")
                font-size: 1.1875rem

                &--featuredAuthor
                    font-size: 1.125rem

            @include include-media.media(">=full")
                font-size: 1.5rem

                &--featuredAuthor
                    font-size: 1.375rem

    .mainHeadlines__row:nth-child(3)

        .storyCard__suptitle

            @include include-media.media(">=min")

                font-size: 0.9375rem
                line-height: 1em

                &--featuredAuthor

                    font-size: 0.875rem

            @include include-media.media(">=full")

                font-size: 1.0rem
                line-height: 1em

                &--featuredAuthor

                    font-size: 0.9375rem

        .storyCard__title

            @include include-media.media(">=min")

                font-size: 1.125rem

                &--featuredAuthor

                    font-size: 1.0625rem

            @include include-media.media(">=full")

                font-size: 1.375rem

                &--featuredAuthor

                    font-size: 1.25rem
