@use "include-media/dist/include-media"
@use "../../../library/breakpoints"

.filler__textEm
    font-size: 1.75rem
    font-weight: 700
    line-height: 1.3em
    display: inline-block

    &--qty-1,
    &--qty-2,
    &--qty-5

        margin-top: 0.1em

        @include include-media.media(">=min")
            font-size: 1.875rem
            font-weight: 600
            margin-top: 0.1em

        @include include-media.media(">=full")
            font-size: 2.375rem
            font-weight: 600
            margin-top: 0.1em

    &--qty-7
        @include include-media.media(">=min")
            font-size: 1.5rem
            font-weight: 600
            margin-top: 0.1rem
        @include include-media.media(">=full")
            font-size: 1.75rem
            font-weight: 600
            margin-top: 0.1rem
