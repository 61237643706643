@use "include-media/dist/include-media"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"
@use "../../../library/breakpoints"
@use "../../videoSticker"

.homeRow

    &__contest
        @include include-media.media(">=min")
            max-width: 246px
            margin: 0

        @include include-media.media(">=full")
            max-width: 300px

    &__contestBody
        margin: 20px auto 0 auto
