@use "include-media/dist/include-media"
@use "../../../../../templates/twig/_components/storyCard/__cover/storyCard__cover--square"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"
@use "../../../library/breakpoints"
@use "../../../library/fonts"
@use "../../videoSticker"
@use "../../podcastSticker"
@use "../../podcastVisual"


.homeRow--doNotMissPosTopPage

    display: none
    @include include-media.media(">=min")
        display: block

    .homeRow
        &__body
            margin-bottom: 0
        &__list
            @include include-media.media(">=min")
                display: flex
                flex-direction: row
                justify-content: space-between
                flex-wrap: wrap
        &__item
            @include include-media.media(">=min")
                flex: 0 1 227px
            @include include-media.media(">=full")
                flex: 0 1 300px

    .storyCard

        margin-top: 30px
        @include include-media.media(">=full")
            margin-top: 35px

        &__details
            display: inline-block
            vertical-align: top
            width: calc(100% - 100px - 20px)
            @include include-media.media(">=min")
                width: calc(100% - 67px - 16px)
            @include include-media.media(">=full")
                width: calc(100% - 80px - 20px)

        &__suptitle

            margin-bottom: 10px
            font-weight: 300
            line-height: normal !important

            @include include-media.media(">=min")
                font-size: 0.8125rem

                &--featuredAuthor
                    font-size: 0.75rem

            @include include-media.media(">=full")
                font-size: 0.875rem

                &--featuredAuthor
                    font-size: 0.8125rem

        &__title

            font-weight: 600
            line-height: normal !important
            margin-bottom: 0

            @include include-media.media(">=min")
                font-size: 0.9375rem

                &--featuredAuthor
                    font-size: 0.875rem

            @include include-media.media(">=full")
                font-size: 1.0rem

                &--featuredAuthor
                    font-size: 0.9375rem

        &__cover
            @include storyCard__cover--square.style

        &__image
            @include include-media.media(">=min")
                @include storyCard__image--square.style

        &__publicationDates
            @include include-media.media(">=min")
                display: none

        @include include-media.media(">=min", "<full")
            @include videoSticker.video-sticker-small
            @include podcastSticker.podcast-sticker-small

        @include podcastVisual.podcastVisual--storycard-small
