@use "include-media/dist/include-media"
@use "../../../../../templates/twig/_components/storyCard/__image/storyCard__image--square"
@use "../../../library/breakpoints"
@use "../../../library/fonts"
@use "../../videoSticker"
@use "../../podcastSticker"

.homeRow--trending

    .adminContainer
        margin-left: 0

    .storyCard

        counter-increment: trendingCard

        &__details
            display: inline-block
            vertical-align: top
            width: calc(100% - 100px - 20px)
            &::before
                display: inline-block
                content: counter(trendingCard, decimal-leading-zero)
                margin-top: -2px
                margin-bottom: 8px
                position: absolute
                width: 56px
                font-size: 2.5em
                line-height: 1em
                font-family: fonts.$fontVerlag
                font-weight: bold
            @include include-media.media(">=min")
                position: initial
                width: 100%
                &::before
                    content: ""
                    display: block

        @include include-media.media("<min")
            &__details .storyCard__badgeCollection
                margin-left: 56px
                padding-bottom: 1em

        &__suptitle
            font-size: 0.875rem
            font-weight: 300
            margin-bottom: 0.625rem
            line-height: normal

            &--featuredAuthor
                font-size: 0.875rem

            @include include-media.media(">=min")
                font-size: 0.9375rem

                &--featuredAuthor
                    font-size: 0.875rem

            @include include-media.media(">=full")
                font-size: 1rem

        &__title
            font-size: 1.125rem
            font-weight: 600
            margin-bottom: 0.75rem
            line-height: normal

            @include include-media.media(">=min")
                font-size: 1.125rem
                line-height: 1.22

                &--featuredAuthor
                    font-size: 1.0625rem

            @include include-media.media(">=full")
                font-size: 1.25rem
                line-height: 1.3

        &__cover
            display: inline-block
            margin-right: 15px
            padding-top: 0
            width: 80px
            height: 80px
            overflow: hidden
            @include include-media.media(">=min")
                display: block
                width: 100%
                padding-top: 66%
                margin-right: 0
                &::after
                    filter: drop-shadow(0 0 32px #000)
                    content: counter(trendingCard, decimal-leading-zero)
                    position: absolute
                    bottom: 0
                    left: 0
                    color: #fff
                    width: 56px
                    text-align: center
                    line-height: 1.165em
                    font-size: 2em
                    font-family: fonts.$fontVerlag
                    font-weight: bold
            @include include-media.media(">=full")
                &::after
                    font-size: 2.15em

        &__image
            @include include-media.media("<min")
                @include storyCard__image--square.style

        @include include-media.media("<min")
            @include videoSticker.video-sticker-small
            @include podcastSticker.podcast-sticker-small
