@use "include-media/dist/include-media"
@use "../../videoSticker"
@use "../../../library/breakpoints"
@use "../../../library/colors"
@use "../../../library/text"

.homeRow--video

    .list
        max-width: calc(100% - 30px)
        margin: 0 auto 32px

        @include include-media.media(">=min")
            max-width: none
            margin-bottom: 0
            display: flex
            flex-direction: row
            justify-content: space-between
            flex-wrap: wrap

        &__item
            @include include-media.media(">=min")
                flex: 0 1 227px
            @include include-media.media(">=full")
                flex: 0 1 300px

    .storyCard

        &__suptitle
            display: block
            font-size: 1.125rem
            font-weight: 300
            line-height: normal
            margin-bottom: 10px

            &--featuredAuthor
                font-size: 1.0625rem

            @include include-media.media(">=min")
                font-size: 0.9375rem

                &--featuredAuthor
                    font-size: 0.875rem

            @include include-media.media(">=full")
                font-size: 1.125rem

                &--featuredAuthor
                    font-size: 1.0625rem

        &__suptitleLink
            @include include-media.media(">=min")
                @include text.line-clamp(1)
                line-height: 1.2em

        &__title
            font-size: 1.5rem
            font-weight: 600
            line-height: normal
            color: colors.$STORY_CARD_TITLE_DARK_GREY
            margin-bottom: 12px

            &--featuredAuthor
                font-size: 1.4375rem

            @include include-media.media(">=min")
                font-size: 1.25rem
                line-height: 1.2em
                &--featuredAuthor
                    font-size: 1.1875rem

            @include include-media.media(">=full")
                font-size: 1.5rem
                line-height: 1.275em
                &--featuredAuthor
                    font-size: 1.375rem
