@use "include-media/dist/include-media"
@use "../library/breakpoints"
.lpplusBanner
    &__wrapper
        height: 630px
        max-height: 630px
        margin: 0 auto
        width: 100vw
        max-width: 428px

        &.priority2
            height: 500px

        @include include-media.media(">=min")
            height: 235px
            max-height: 235px
            width: 100vw
            max-width: 100%
        @include include-media.media(">=full")
            height: 296px
            max-height: 296px
            width: 100vw
            max-width: 100%

    &__iframe
        width: 100%
        height: 100%
        overflow: hidden
