
@use "__title/callForTestimony__title"
@use "__img/callForTestimony__img"
@use "__content/callForTestimony__content"

.callForTestimony
    position: relative
    padding: 15px 15px 22px 15px
    background: #000
    text-align: center

    &::before
        content: ""
        position: absolute
        top: 22px
        border-bottom: 1px solid #444
        width: calc(100% - 30px)
        left: 15px
