@use "include-media/dist/include-media"
@use "../../../library/breakpoints"

.homeHeadlinesRow__aside
    @include include-media.media(">=min")
        grid-row: 1
        grid-column: 2
        width: 300px
        margin-bottom: 0
        height: 100%
