@use "../library/breakpoints"
@use "include-media/dist/include-media"
@use "../library/fonts"

.flashnews

    background: linear-gradient(90deg, #ce0018 0%, #e1001a 100%)
    padding: 15px
    color: #fff

    @include include-media.media(">=min")
        width: breakpoints.$page-min-width
        margin: 30px auto 0 auto

    @include include-media.media(">=full")
        width: breakpoints.$page-full-width
        margin: 35px auto 0 auto

    &__heading
        position: relative
        border-bottom: 1px solid rgba(255, 255, 255, 0.5)
        text-transform: uppercase
        font-family: fonts.$fontVerlag
        font-weight: 900
        font-size: 0.75em
        line-height: 1.5
        padding: 0 0 10px 25px
        margin-bottom: 10px

        &::before
            content: ""
            background-image: url("assets/ic-live.inline.svg")
            background-size: cover
            position: absolute
            width: 24px
            height: 24px
            top: -3px
            left: 0

    &__content
        padding: 10px 0
        font-family: fonts.$fontVerlag

    &__title
        font-weight: bold
        font-size: 1.125em
        line-height: 1.18
        margin-bottom: 3px
        @include include-media.media(">=min")
            font-size: 1.375em

    &__body
        font-weight: normal
        font-size: 1em
        line-height: 1.3
        @include include-media.media(">=min")
            font-size: 1.25em

    em
        font-style: italic

    sub
        line-height: 0.5em
        font-size: 0.75em
        vertical-align: sub

    sup
        line-height: 0.5em
        font-size: 0.75em
        vertical-align: super

