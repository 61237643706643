@use "../../../library/colors"
@use "../../../components/podcastVisual"
@use "include-media/dist/include-media"

.homeHeadlinesRow--displayMode-grouped

    .mainHeadlines
        &__row
            margin-top: 0

        &__GroupList
            gap: 0

    .homeHeadlinesRow__list
        width: 100%
        background-color: #000
        position: relative

    .homeHeadlinesRow__listItem
        z-index: 2

        &:first-child
            max-width: 100%
            margin: 0
            z-index: 1

        @include include-media.media(">=min")
            &:last-child
                padding-bottom: 15px

    .homeHeadlinesRow__main
        .storyCard
            border-color: #4f4a4a
            margin: 0 15px auto 15px
            padding-bottom: 15px
            @include include-media.media(">=min")
                padding-bottom: 0
                margin: 15px 0 auto 15px

            &__inner
                height: 100%

            &__suptitle
                color: white
                font-size: 1.0rem
                line-height: 1.28em
                font-weight: 300

                @include include-media.media(">=min")
                    font-size: 0.875rem
                    line-height: 1.28em
                    a
                        line-height: inherit

                    &--featuredAuthor
                        font-size: 0.8125rem

                @include include-media.media(">=full")
                    font-size: 1.0rem
                    line-height: 1.28em
                    a
                        line-height: inherit

                    &--featuredAuthor
                        font-size: 0.9375rem

            &__title
                color: white
                font-size: 1.25rem
                line-height: 1.2em
                font-weight: 900
                margin-bottom: 20px

                @include include-media.media(">=min")
                    font-size: 1.125rem
                    line-height: 1.22em
                    margin-bottom: 10px

                    &--featuredAuthor
                        font-size: 1.0625rem

                @include include-media.media(">=full")
                    font-size: 1.25rem
                    line-height: 1.2em

                    &--featuredAuthor
                        font-size: 1.1875rem

            &__lead
                color: white

            &__cover
                display: none

            &__publishedDate
                color: colors.$PUBLISHED_DATE_LIGHT

            &__modifiedDate
                color: colors.$MODIFIED_DATE_LIGHT

            &--position-1
                padding: 0
                border: none
                margin: 0

                .storyCard__details
                    position: relative
                    top: -25px
                    background: none
                    height: auto

                    @include include-media.media(">=min")
                        top: initial
                        padding: 0 10px 30px 10px
                        width: 629px
                        margin-top: -10%

                    @include include-media.media(">=full")
                        width: 880px
                        padding: 0 15px 30px 15px

                .storyCard__suptitle

                    font-size: 1.125rem
                    line-height: 1.28em

                    &--featuredAuthor
                        font-size: 1.0625rem

                    @include include-media.media(">=min")

                        font-size: 1.125rem
                        line-height: 1.28em

                        &--featuredAuthor
                            font-size: 1.0rem

                    @include include-media.media(">=full")

                        font-size: 1.25rem
                        line-height: 1.28em

                        &--featuredAuthor
                            font-size: 1.125rem

                .storyCard__title
                    font-size: 1.875rem
                    line-height: 1.13em
                    margin-bottom: 15px

                    &--featuredAuthor
                        font-size: 1.75rem

                    @include include-media.media(">=min")

                        font-size: 2.5rem
                        line-height: 1.25em
                        margin-bottom: 10px

                        &--featuredAuthor
                            font-size: 2.625rem

                    @include include-media.media(">=full")

                        font-size: 2.875rem
                        line-height: 1.25em
                        margin-bottom: 20px

                        &--featuredAuthor
                            font-size: 2.75rem


                .storyCard__lead
                    display: block !important
                    font-size: 1.125rem
                    line-height: 1.33em
                    font-weight: 300

                    &--featuredAuthor
                        font-size: 1.0625rem

                    @include include-media.media(">=min")

                        font-size: 1.25rem
                        line-height: 1.5em
                        &--featuredAuthor
                            font-size: 1.125rem

                    @include include-media.media(">=full")

                        font-size: 1.375rem
                        line-height: 1.45em

                        &--featuredAuthor
                            font-size: 1.25rem

                .storyCard__leadLink
                    -webkit-line-clamp: initial

                .storyCard__cover

                    display: block
                    padding-top: 66%
                    overflow: hidden

                    @include include-media.media(">=min")
                        width: 630px
                        height: 420px

                    @include include-media.media(">=full")
                        width: 880px
                        height: 589px

                    &::after
                        content: ""
                        position: absolute
                        top: 0
                        left: 0
                        right: 0
                        bottom: 0
                        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 25%)

                .storyCard__image
                    height: 100%

                .storyCard__blurredOverlay
                    width: 395px
                    position: absolute
                    right: -395px

                    @include include-media.media(">=full")
                        right: -880px
                        width: 410px

                    &::before
                        content: ""
                        position: absolute
                        left: 0
                        right: 0
                        top: 0
                        bottom: 0
                        background: rgba(0, 0, 0, 0.5)
                        z-index: 1

                    &::after
                        content: ""
                        position: absolute
                        width: 100%
                        top: 290px
                        bottom: 0
                        background: linear-gradient(0deg, black 0%, black 60%, rgba(0, 0, 0, 0) 100%)
                        @include include-media.media(">=full")
                            top: 440px

                    img
                        opacity: initial
                        height: 420px
                        @include include-media.media(">=full")
                            height: 589px

                .podcastEpisode__visual
                    z-index: 0

                    &:before
                        z-index: -1

                    @include include-media.media(">min")
                        z-index: -1

                    .podcastEpisode__imageWrapper
                        &:before, &:after
                            z-index: -1

                @include podcastVisual.podcastVisual--storycard-large

            &--position-2
                margin-top: 0 !important
