@use "include-media/dist/include-media"

.pageBox
    margin-top: 40px

    @include include-media.media(">=min")
        margin-top: 60px

    @include include-media.media(">=full")
        margin-top: 80px

    &--aside
        margin-top: 40px

        @include include-media.media(">=min")
            margin: 0 0 20px 0
            &:last-of-type
                margin: 0

    &--panoramic
        background-color: #E8E8E8
        box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, 0.21)
        &::after
            content: ''
            display: block
            height: 8px
            width: 100%
            box-shadow: inset 0 -8px 8px -8px rgba(0, 0, 0, 0.21)
